.overflow-pager {
  position: relative;
  height: 100%;
  flex-grow: 1;

  .pager-content {
    height: 100%;
    overflow: hidden;
  }

  .pager-inner {
    transition: 1.2s transform cubic-bezier(0.61, -0.02, 0.28, 0.99);
  }

  .pager-indicators {
    position: absolute;
    right: -1.4rem;
    top: 0;
    height: 100%;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    .indicator {
      width: 0.8rem;
      height: 0.8rem;

      border-radius: 50%;
      background-color: white;

      opacity: 0.3;
      margin: 0.4rem 0;

      flex-shrink: 0;
      transition: 0.5s opacity;

      &.current {
        opacity: 1;
      }

      &.single {
        opacity: 0;
      }
    }
  }
}
