.app {
  padding: 2.6rem 3.4rem;
  transition: 0.5s opacity;

  &.dual-display {
    padding: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    .dual-screens {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 2.4rem;
    }

    .dual-screen iframe {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
    }

    .header {
      padding: 2.6rem 3.4rem;
      padding-bottom: 0;
    }
  }

  &.dual-display-element {
    padding-top: 0;
  }
}
