.news-element.element-news-item {
  background: #f7f7f7;
  color: #000;

  .news-header {
    padding: 4.4rem 5.2rem;
    padding-top: 8.2rem;
    background-image: linear-gradient(-135deg, #107df5 0%, #cf5cff 100%);
  }

  .card-title {
    color: #fff;
    font-size: 4rem;
    line-height: 4.8rem;
    margin: 0;
  }

  .news-content {
    padding: 2.2rem 5.4rem;
    font-size: 2.2rem;
  }

  .card-lead {
    font-weight: bold;

    margin-bottom: 2.4rem;
    margin-top: 0.8rem;
  }

  .card-content {
    margin-top: 1.2rem;
    padding-bottom: 0.6rem;
  }
}
