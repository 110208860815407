.timetable {
  height: calc(100% - 2rem);
  padding-bottom: 2.4rem;

  .timetable-title {
    font-size: 3.2rem;
    font-weight: bold;

    padding-bottom: 1.8rem;

    & + .row-header + .overflow-pager {
      height: calc(100% - 10rem);
    }
  }

  .timetable-row,
  .timetable-lessons,
  .timetable-times {
    display: flex;
    flex-grow: 1;
  }

  .timetable-scheduler {
    display: flex;
    flex-direction: column;
    height: 100%;

    .timetable-row.row-header {
      flex-shrink: 0;
    }

    .overflow-pager {
      height: calc(100% - 5.4rem);
    }
  }

  .timetable-lessons {
    width: calc(100vw - 22.5rem);
    flex-grow: 0;
  }

  .timetable-row,
  .timetable-times {
    margin: 0.4rem 0;
    overflow: hidden;
  }

  .timetable-scheduler.config-wide .timetable-lessons {
    width: calc(100vw - 21.5rem);
  }

  .timetable-times {
    margin-left: 10.8rem;
    margin-right: 0.4rem;
    margin-top: 0;

    .header-timeslot {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 1.4rem;
      letter-spacing: 0.05rem;
      font-weight: 500;

      color: #afbac7;
      margin: 0 0.2rem;
      padding: 0.8rem 0;
      border-radius: 0.5rem;

      transition: 0.75s all;

      &.past {
        opacity: 0.5;
      }

      &.now {
        background-color: rgb(255, 245, 54);
        color: black;
      }
    }
  }

  .timetable-row {
    &.turquoise .timetable-class {
      background-color: #7ccbc6;
      color: #000;
    }
  }

  .timetable-class {
    width: 10rem;

    background-color: #fbf8ea;
    color: #000;

    border-radius: 0.5rem;
    margin-right: 0.8rem;

    font-weight: bold;

    display: flex;
    flex-shrink: 0;

    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .lesson-timeslot {
    background-color: #1e242c;
    border-radius: 0.5rem;
    margin: 0 0.2rem;

    flex-shrink: 0;
    flex-grow: 0;

    overflow: hidden;
    word-break: break-all;

    box-sizing: border-box;
    padding: 0.8rem 1rem;

    font-size: 1.6rem;
    letter-spacing: 0.08rem;
    min-height: 8.8rem;

    transition: 0.75s all;

    &.past {
      opacity: 0.45;
    }
    &.now {
      background-color: #354050;

      &.empty {
        background-color: #242d39;
      }
    }

    &.compact {
      font-size: 1.4rem;
    }

    .lesson {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .lesson-title {
      font-weight: 600;
      font-size: 1.4rem;
    }

    .lesson-room {
      margin-bottom: 0.8rem;
    }

    .lesson-teacher {
      color: #d0dbea;
      font-size: 1.35rem;
      font-weight: 500;
      letter-spacing: 0.1rem;
    }

    .lesson-title,
    .lesson-teacher {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.empty {
      background-color: #0f1216;
    }

    &.multiple-lessons {
      .lesson + .lesson {
        margin-top: 1rem;
        padding-top: 1rem;
        border-top: 0.2rem solid #000;
      }
    }
  }
}
