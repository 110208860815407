.news-element.element-callout {
  background: #ffee45;
  text-align: center;

  .callout-text {
    font-size: 4.8rem;
    line-height: 6.4rem;

    letter-spacing: 0;
    padding: 3.8rem 4rem;
    font-weight: bold;
    color: #000;
  }
}
