.configuration-error {
  background: #000;
  position: fixed;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 100;

  .error {
    width: 51.6rem;
    margin: 0 auto;

    position: fixed;
    left: 50%;
    top: 50%;

    margin-left: -25.8rem;
    margin-top: -10.6rem;
    height: 16.8rem;
  }

  .error-title {
    font-size: 2.4rem;
    font-weight: bold;
  }

  .error-reason,
  .error-help {
    margin: 3rem 0;
  }

  .error-reason {
    font-size: 1.9rem;
  }

  .error-help {
    color: #aaa;
    font-size: 1.5rem;
  }
}
