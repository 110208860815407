.news-empty {
  display: flex;
  align-items: center;
  justify-content: center;

  flex-grow: 1;

  span {
    font-size: 4.4rem;
    font-weight: bold;

    text-align: center;
    padding: 10rem 0;

    display: block;
    max-width: 80rem;
  }
}
