.news-area {
  height: 100%;
  margin-bottom: 2rem;

  .news-callouts {
    margin-bottom: 2rem;
  }

  .news-columns {
    display: flex;

    .news-column {
      width: 50%;
      box-sizing: border-box;
      padding-right: 1rem;

      & + .news-column {
        padding-left: 1rem;
        padding-right: 0;
      }
    }
  }
}
