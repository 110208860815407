.content-area.agenda {
  .content-area-header h2 {
    margin-left: 24rem;
  }

  .calendar {
    padding: 2.4rem 0;

    .day {
      display: flex;
      border-top: 0.1rem solid rgba(255, 255, 255, 0.08);
      padding-top: 1.6rem;
      margin-bottom: 1.6rem;

      .date {
        width: 20rem;
        margin-right: 4rem;

        flex-shrink: 0;
        font-weight: 500;
        text-align: right;
        padding-right: 4rem;
        box-sizing: border-box;

        span {
          display: block;
        }

        .natural {
          font-size: 2.4rem;
          font-weight: 700;
          margin: 1rem 0;
        }

        .day-number {
          font-size: 3.6rem;
          font-weight: 700;
        }
      }

      .events {
        flex-grow: 1;
        padding-right: 16rem;
      }
    }

    .event {
      background-color: #1d2227;
      border-radius: 0.6rem;
      padding: 1.2rem 1.6rem;
      margin-bottom: 0.6rem;

      h3 {
        margin: 0;
        font-weight: 500;
      }

      .until {
        opacity: 0.8;
        font-size: 1.4rem;
        letter-spacing: 0.06rem;
      }
    }
  }

  .no-events-note {
    font-size: 1.8rem;
    margin: 1rem 0;
  }
}
