.timetable .lesson-timeslot {
  &.all-room-changes {
    background-color: #093017;
  }
  &.all-room-changes.now {
    background-color: #0f4522;
  }
  .lesson.has-room-change .lesson-room {
    background: #1fe264 url("../../../assets/icons/icon-rmchg.svg") no-repeat 0.4rem center;
    background-size: 1.6rem 1.6rem;
    color: black;

    font-weight: bold;
    border-radius: 0.4rem;
    align-self: flex-start;

    padding-left: 2.4rem;
    padding-right: 0.6rem;
    margin-top: 0.2rem;
  }

  &.all-canceled {
    background-color: #ff1a5b;
    color: black;
  }
  .lesson.is-canceled {
    background-color: #ff1a5b;
    color: black;
    border-radius: 0.4rem;

    margin: -0.2rem -0.4rem;
    padding: 0.2rem 0.4rem;

    &.has-room-change .lesson-room {
      background: inherit;
      padding: 0;
      font-weight: normal;
    }

    .cancel-notice {
      background: transparent url("../../../assets/icons/icon-cancel.svg") no-repeat 0 center;
      background-size: 1.6rem 1.6rem;
      padding-left: 2rem;

      color: black;
      font-weight: bold;
    }
  }
}
