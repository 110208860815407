.news-element.element-image {
  img {
    width: 100%;
    border-radius: 0.8rem;
  }

  &.fill {
    img {
      height: 100%;
      width: auto;

      margin: 0 auto;
      display: block;
    }
  }
}
