.content-area.mensa {
  background-color: #0a1f1b;

  h2 {
    color: #7ff47f;
  }

  .menues {
    display: flex;
    margin-top: 3.2rem;

    .menue {
      margin-right: 4rem;

      .type {
        font-weight: bold;
        text-transform: uppercase;
        color: #80f47f;
        letter-spacing: 0.1rem;
      }

      .title {
        font-size: 2.4rem;
        margin: 1.2rem 0;
        line-height: 3.2rem;

        &:first-child {
          margin-top: 0;
        }
      }

      .price {
        font-size: 1.55rem;
        letter-spacing: 0.05rem;
        font-weight: 600;
        color: #949fa3;
      }

      & + .menue {
        padding-left: 2.4rem;
        border-left: 0.1rem solid rgba(255, 255, 255, 0.1);
      }
    }
  }
}
