.loading-screen {
  position: fixed;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;

  .overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0);

    width: 100%;
    height: 100%;

    backdrop-filter: blur(0rem);
    transition: 1s backdrop-filter, 1s background-color;
  }

  .content {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;

    opacity: 0;
    transition: 1s opacity ease-in-out;

    .loading-brand {
      margin-bottom: 6rem;
    }

    .loading-bar {
      height: 0.6rem;
      width: 26em;
      background-color: rgba(255, 255, 255, 0);
      overflow: hidden;

      .bar-inner {
        background-color: white;
        height: 100%;

        animation: loading-bar 1.5s ease-in-out infinite;
      }
    }
  }

  &.visible {
    .content {
      opacity: 1;
    }

    .overlay {
      backdrop-filter: blur(2rem);
      background-color: rgba(0, 0, 0, 0.35);
    }
  }
}

@keyframes loading-bar {
  0% {
    width: 0%;
  }
  50% {
    width: 100%;
  }
  100% {
    margin-left: 100%;
    width: 0%;
  }
}
