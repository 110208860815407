.news-element {
  border-radius: 0.8rem;
  overflow: hidden;

  & + .news-element {
    margin-top: 2rem;
  }

  &.fill {
    height: calc(100vh - 16.5rem);
  }
}
