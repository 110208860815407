.header {
  display: flex;
  align-items: center;

  .brand {
    margin-right: 2rem;
  }

  .date {
    margin-left: 1.4rem;
    padding-left: 3.6rem;
    line-height: 2.6rem;
    border-left: 0.1rem solid #222429;

    .full-date {
      display: block;

      font-weight: 800;
      font-size: 2rem;
    }
  }

  .clock {
    margin-left: auto;
    font-size: 3.6rem;

    display: flex;
    align-items: center;

    .watchface {
      height: 8rem;
      margin-left: 2.6rem;
    }

    .time {
      font-weight: 600;

      .colon {
        font-weight: 400;
        animation: time-blink 1s linear 0s infinite alternate;
      }
    }

    @keyframes time-blink {
      from {
        opacity: 1;
      }
      to {
        opacity: 0.2;
      }
    }
  }
}
