.content-error {
  position: fixed;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;
  background-color: black;

  .error-inner {
    position: relative;
    display: flex;

    align-items: center;
    flex-direction: column;
  }

  .error-explanation {
    margin-top: 4.8rem;
    opacity: 0.8;

    font-size: 1.4rem;
    letter-spacing: 0.075rem;

    span {
      display: block;
      margin-top: 0.6rem;
      text-align: center;

      &.technical {
        font-size: 1.3rem;
        opacity: 0.6;
      }
    }
  }
}
