.content-grid {
  &.blueprint-mode {
    background: transparent url("../../assets/other/grid-backdrop.svg") repeat;
    background-size: 1.7rem 1.7rem;
  }

  display: flex;
  flex-direction: column;

  margin-top: 2rem;
}

.content-area {
  background-color: #13171b;
  border-radius: 0.75rem;
  margin-bottom: 2rem;

  flex-shrink: 1;
  padding: 2.8rem 3.2rem;

  position: relative;

  .content-area-header {
    display: flex;
    align-items: center;

    img {
      margin-right: 1.6rem;
      width: 4rem;
    }

    h2 {
      margin: 0;
      font-size: 3.6rem;
    }
  }

  &.fades-off {
    overflow: hidden;

    .content-area-fade {
      position: absolute;

      width: 100%;
      height: 6rem;

      bottom: 0;
      left: 0;

      background-image: linear-gradient(180deg, rgba(19, 23, 27, 0) 0%, rgba(19, 23, 27, 1) 66%);
    }
  }
}
