.debug-overlay {
  position: fixed;
  top: 1rem;

  background: #000 url("../../../assets/other/blur-backdrop.png") no-repeat center top;
  background-size: cover;
  color: white;

  padding: 3.2rem 2.8rem;

  max-height: 70rem;
  z-index: 999;

  overflow: hidden;
  border-radius: 0.6rem;

  width: 60vw;
  left: 50%;
  margin-left: -30vw;

  display: flex;
  flex-direction: column;
  align-items: center;

  .brand {
    width: 16.6rem;
    height: 2.2rem;

    background: transparent url("../../../assets/brand/logo-saliorel-display.svg") no-repeat center
      center;
    background-size: auto 2.2rem;

    &.type-news {
      background-image: url("../../../assets/brand/logo-saliorel-news.svg");
    }
  }

  .display-name {
    font-size: 2.8rem;
    text-align: center;
  }

  .info-row {
    display: flex;
    margin-bottom: 2rem;

    font-size: 1.4rem;
    letter-spacing: 0.08rem;

    .device-info,
    .software-info,
    .configuration-info {
      background: transparent url("../../../assets/icons/info-system-information.svg") no-repeat 0
        center;
      background-size: auto 2rem;

      padding-left: 2.8rem;
      margin: 0 1.2rem;
    }

    .device-info {
      background-image: url("../../../assets/icons/info-fingerprint.svg");
    }
    .configuration-info {
      background-image: url("../../../assets/icons/info-link.svg");
    }
  }

  .logs {
    width: 100%;
    margin-top: 1.2rem;

    font-size: 1.4rem;
    letter-spacing: 0.08rem;

    .log-item {
      display: flex;
      padding: 0.2rem 0;

      .time {
        width: 10rem;
        flex-shrink: 0;
      }
      .message {
        flex-grow: 1;
      }
    }
  }
}
